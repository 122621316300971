$theme-1: #A2B238; //Primary
$theme-2: #6CA7D7;
$theme-3: #BA4A75;
$theme-4: #E08230;
$theme-5: #868FB5;
$theme-6: #d0c3ae;
$light-gray: #F2F2F2; //Light gray theme support color

//Binnenmaas
$theme-binnenmaas-1: #002D10;
$theme-binnenmaas-2: #009FE3;
$theme-binnenmaas-3: #E6007E;
$theme-binnenmaas-4: #65B32E;
$theme-binnenmaas-5: #FFCC00;

$facebook: #3B5998;
$instagram: #0077B5;
$tiktok: #EE1D51;

$primary: $theme-1 !default;
$secondary: $theme-2 !default;
$dark: #0E1843;
$success: $theme-1;

$border-width: 1px;
$input-border-color: gray;
$theme-colors: (
        'theme-1': $theme-1,
        'theme-2': $theme-2,
        'theme-3': $theme-3,
        'theme-4': $theme-4,
        'theme-5': $theme-5,
        'theme-6': $theme-6,
        'theme-binnenmaas-1': $theme-binnenmaas-1,
        'theme-binnenmaas-2': $theme-binnenmaas-2,
        'theme-binnenmaas-3': $theme-binnenmaas-3,
        'theme-binnenmaas-4': $theme-binnenmaas-4,
        'theme-binnenmaas-5': $theme-binnenmaas-5,
        'light-gray': $light-gray,
        'facebook': $facebook,
        'instagram': $instagram,
        'tiktok': $tiktok,
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1900px
);

$input-btn-border-width: 2px;
$btn-border-width: $input-btn-border-width;
$btn-color: #fff;
$btn-primary-color: #fff;
$btn-secondary-color: #fff;

$border-radius: 10px;
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius;
$btn-border-radius-sm: $border-radius;

$hamburger-layer-color: $dark !default;
$hamburger-active-layer-color: $dark !default;

$line-height-base: 1.65;

$owl-image-path: '~owl.carousel/src/img/';
@import "~owl.carousel/src/scss/owl.carousel";

// adobe font
@import url("https://use.typekit.net/jdb4dts.css");

$font-family-base: "neue-haas-grotesk-display";

@import '../../../../src/scss/main.scss';
@import '../../../../src/scss/components/hero-video.scss';

body {
  color: #6e6e6e;
  letter-spacing: .05rem;
}

.btn-primary, .btn-secondary {
  color: white;
}

h1, h2, h3, h5 {
  font-weight: 600;
  color: $dark;
}

.bg-p--center {
  background-position: center center;
}

.bg-p--top {
  background-position: top center;
}

.shoppingcart--icon {
  @extend .text-dark;
}

.breadcrumb--bar {
  background-color: $light-gray;
}

h4 {
  font-weight: 500;
  color: $dark;
}

.accommodatie--widget {
  a {
    color: $secondary;
    &:hover {
      text-decoration: none;
      color: $theme-1;
    }
  }
}

.overlay--image {
  left: 0;
  max-width: 60% !important;
  width: auto !important;
  margin-top: 40px;
}

.image-style-1 {
  border-radius: $border-radius;
  @extend .h-100;
}

.overlay--image-left {
  left: -150px;
  max-height: 120% !important;
  width: auto !important;
  margin-top: 0;
  @include media-breakpoint-down(lg) {
    max-height: 100% !important;
    left: -100px;
  }
  @include media-breakpoint-down(md) {
    max-height: 60% !important;
  }
}

.overlay--image-right {
  right: -150px;
  max-height: 120% !important;
  width: auto !important;
  margin-top: 0;
  @include media-breakpoint-down(lg) {
    right: -40px;
  }
}

.page-head-banner {
  object-fit: cover;
  @extend  .h-100;
}

.video-hero-wrapper-mobile {
  .hero-title {
    max-width: 100%;
    @extend .d-flex;
    justify-content: center;
    align-items: center;
    top: 1vh;
  }
  .hero-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #0000006e;
    top: 0;
    z-index: 0;
  }
}

#Rooster {
  h4, h3 {
    color: $primary;
    font-weight: 600;
  }
  a {
    text-decoration: underline;
    color: $secondary;
  }
  thead {
    border-bottom: solid $secondary;
    th {
      background: $light-gray;
      border: none;
      &:first-child {
        border-radius: 10px 0 0 0;
      }
      &:last-child {
        border-radius: 0 10px 0 0;
      }
    }
  }
}

.search--component {
  button {
    @extend .btn-dark;
  }
}

.mobile-hamburger-color {
  @extend .my-3;
}

.nav-item {
  a {
    color: $dark;
    font-weight: 600;
    letter-spacing: 0.05rem;
  }
  @include media-breakpoint-up(lg) {
    text-align: left;
    .dropdown-menu.show {
      transition: all .3s ease-in-out;
      display: contents;
      a {
        transition: all .3s ease-in-out;
        width: 0px;
      }
    }
    .dropdown-item:hover {
      background: white;
    }
  }
}

.bg-header-bar {
  .navbar-collapse {
    @include media-breakpoint-up(md) {
      padding-left:70px;
    }
  }
}

.navbar-nav {
  .dropdown-menu {
      margin-top: 0 !important;
      padding-top: 0 !important;

  }
}

.sub-dropdown.show {
  background-color: $light-gray;
}

.navbar-brand {
  display: contents;
  img {
    max-width: 250px;
    width: 100%;
  }
}

#main-content {
  min-height: 55vh;
  h6 {
    color: $primary;
  }
}

.img--agenda {
  border-radius: $border-radius 0 0 $border-radius;
  background-size: cover;
}

.login--button {
  @extend .btn-light-gray;
  @extend .text-dark;
  @extend .ml-0;
}
.bg-header-bar {
  .dropdown-menu {
    @extend .border-0;
    @extend .position-static;
    a {
      font-weight: 400;
    }
  }
}

.bg-header-bar, .alert-light {
  border-bottom: solid $light-gray 1px;
  .container {
    align-items: flex-start !important;
  }
  .navbar-brand {
    img {
      margin-top: 6px;
    }
  }
}

.btn-theme-1, .btn-theme-2 .btn-dark {
  @extend .px-4;
}

// video header
.video-hero-overlay-opaque {
  display: block;
  opacity: 0.4;
}

.activity-carousel {
  @include media-breakpoint-up(lg) {
    height: 217px;
  }
}

.activity-carousel--title {
  @include media-breakpoint-up(lg) {
    height: 135px;
  }
}

.banner--flavour-img {
  @include media-breakpoint-down(md) {
    object-fit: cover;
    aspect-ratio: 2.1;
  }
  @include media-breakpoint-down(sm) {
    object-fit: cover;
    aspect-ratio: 1.6;
  }
}

.banner-img--circle {
  max-width: 160px !important;
  bottom: -50%;
}

.video-hero--title {
  @extend h6;
  @extend .text-primary;
  width: 60%;
  text-shadow: 0px 1px 1px #0000004d;
}

.text-shadow {
  text-shadow: 0px 1px 1px #0000004d;
}

.video-hero-wrapper {
  height: 230px;
  @include media-breakpoint-up(md) {
    height: 600px;
    padding-top: 5vw;
  }
}

.special-page--header {
  margin-bottom: -110px;
}

.wrapper--subhome {
  padding-top: 0 !important;
  @include media-breakpoint-down(md) {
    height: 430px;
  }
}

.video-hero--subtitle {
  @extend h1;
  font-size: 3rem;
  @extend .text-white;
  @extend .mb-3;
  display: block;
  @include media-breakpoint-down(sm) {
    font-size: 1.5em;
  }
}

.video-hero-overlay-text {
  @extend .p-0;
}

.header--subhome {
  padding-top: 330px !important;
  @include media-breakpoint-down(md) {
    padding-top: 300px !important;
  }
}

.stretched-link {
  .fa-arrow-right {
    margin-left: 5px;
  }
  &:hover {
    text-decoration: none;
    .fa-arrow-right {
      margin-left: 10px;
    }
  }
}

.card-cta--body {
  margin-top: -50px;
  .btn {
    bottom: -20px;
    right: 40px;
  }
}

.btn--float {
  bottom: -20px;
  right: 60px;
}

.card-cta-button--body {
   margin-top: -50px;
   .btn {
     bottom: -20px;
     align-self: center;
   }
}
.card-cta--subbody {
  margin-top: -50px;
  max-width: 770px;
  width: 90%;
}

.review--btn {
  a {
    aspect-ratio: 1/1;
  }
}

.image--overlay {
  position: relative !important;
  bottom: 0;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -25px;
  border-radius: $border-radius;
  z-index: 3;
}

.image--cta-left {
  min-height: 260px;
}

.semi-bold {
  font-weight: 500;
}

.quotes--img {
  filter: brightness(0) saturate(100%) invert(12%) sepia(11%) saturate(7474%) hue-rotate(207deg) brightness(94%) contrast(106%);
  width: 60px;
  margin-top: -90px;
}

.subhome--img {
  width: 370px;
  max-width: 80%;

  @include media-breakpoint-down(md) {
    max-width: 70%;
    width: 230px;
  }
}

.top--banner {
  margin-top: -100px;
  @include media-breakpoint-down(md) {
    margin-top: -50px;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }
}

.home-banner {
  z-index: 151;
  .home-banner-item {
    @include media-breakpoint-down(md) {
      max-height: 165px;
      width: 100%;
      object-fit: cover;
    }
  }
}

.leftside--img {
  width: 70%;
  img {
    width: 100%;
  }
  @include media-breakpoint-down(lg) {
    margin-right: -60px;
    width: 100%;
  }
  @include media-breakpoint-down(md) {
    margin-right: 0;
    width: 100%;
  }
}
.rightside--img {
  width: 70%;
  margin-left: 30%;
  img {
    width: 100%;
  }
  @include media-breakpoint-down(lg) {
    position: relative;
    margin-left: 5%;
    width: 100%;
  }
  @include media-breakpoint-down(md) {
    margin-left: 0;
  }
}

.rightside--banner {
  width: 50%;
  margin-left: 50%;
  align-self: center;
  .btn {
    position: absolute;
    margin-top: 27px;
    right: 40px;
  }
  a:not(.btn) {
    color: white !important;
    text-decoration: underline;
    &:hover {
      color: $light-gray;
    }
  }
  @include media-breakpoint-down(lg) {
    margin-left: 5%;
    z-index: 20;
    position: relative !important;
    margin-top: -50px;
  }
  @include media-breakpoint-down(md) {
    width: 90%;
  }
}

.leftside--banner {
  width: 50%;
  align-self: center;
  .btn {
    position: absolute;
    margin-top: 27px;
    left: 40px;
  }
  a:not(.btn) {
    color: white !important;
    text-decoration: underline;
    &:hover {
      color: $light-gray;
    }
  }
  @include media-breakpoint-down(lg) {
    margin-left: 0;
    margin-right: -60px;
    z-index: 20;
    position: relative !important;
    margin-top: -50px;
  }
  @include media-breakpoint-down(md) {
    margin-right: 0;
    margin-left: 5%;
    width: 90%;
  }
}

.tumb--image {
  width: 70px;
}

.footer {
  .footer--body {
    color: #c4c4c4;
    h4 {
      color: $primary;
      font-weight: 600;
    }
    a:not(.btn) {
      color: #c4c4c4;
    }
  }
  .footer--socials {
    margin-top: -34px !important;
    h4 {
      color: $dark;
      font-weight: 600;
    }
    a {
      color: $dark;
      border-radius: 100px;
      background-color: white;
      display: inline-flex !important;
      width: 40px;
      margin-right: 1em;
      height: 40px;
      i {
        margin: auto;
      }
    }
  }
}

.watermark {
  @extend .m-0;
  opacity: 1 !important;
}

// pagination
.pagination {
  .page-link {
    @extend .mx-2;
    color: $dark;
    border-radius: 3px !important;
    &:hover, &.active {
      background-color: $theme-2;
      border-color: $theme-2;
    }
    &[aria-label="Next"] {
      background-color: $light-gray;
    }
  }
}

// mijn omgeving
.sidebar {
  @extend .bg-theme-1;
}

.sidebar {
  min-width: 500px;
  max-width: 500px;
  background: $primary;
  transition: all .3s;
  line-height: 1.4;
  position: relative;
  z-index: 1;
  @include media-breakpoint-down(md) {
    min-width: 100%;
    min-height: unset;
  }
  .sidebar-nav {
    padding: 0 6rem;
    @include media-breakpoint-down(md) {
      padding: 0 1rem;
    }
  }
}
.card-sidebar {
  border: 0;
  .btn {
    padding-left: 2em;
    padding-right: 2em;
    font-size: 14px;
    font-weight: 600;
    @extend .btn-primary;
  }
}

.menu-block {
  border: 2px solid $white;
  border-radius: 15px;
  align-content: center;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 15px;
  transition: all 0.3s;

  &:hover {
    background-color: $dark;
  }

  &.active {
    background-color: $dark;
  }

  a {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: $white;
    width: 100px;
    height: 100px;

    i.fas {
      display: inline-block;
      width: 100%;
    }

    &:hover {
      text-decoration: none;
    }

  }
}

.content-wrapper {
  @extend .mt-4;
}

//.header--scaler {
//  @include media-breakpoint-down(lg) {
//    font-size: 1.5vw !important;
//  }
//  @include media-breakpoint-down(md) {
//    font-size: 2vw !important;
//  }
//  @include media-breakpoint-down(sm) {
//    font-size: 2.5vw !important;
//  }
//}


.bg-cover {
  background-size: cover;
}

// Custom form

input, select, textarea, .input-group-text {
  @extend .border-0;
  @extend .bg-light-gray;
}

.card-cta--subbody {
  label:not(.custom-file-label) {
    display: none !important;
  }

  input, select, textarea {
    @extend .border-0;
    @extend .pl-0;
    border-radius: 0 !important;
    border-bottom: solid 1px #494949FF !important;
    background-color: rgba(0,0,0,0) !important;
  }
  .btn {
    @extend .d-flex;
    @extend .mx-auto;
  }
}

// owl nav
.next {
  @extend .btn;
  @extend .btn-dark;
  @extend .p-3;
  @extend .m-1;
}

.prev {
  @extend .btn;
  @extend .btn-outline-primary;
  @extend .border-0;
  @extend .bg-white;
  @extend .p-3;
  @extend .m-1;
}

.prev-alt {
  @extend .btn;
  @extend .btn-outline-primary;
  @extend .p-3;
  @extend .m-1;
}

.owl-nav {
  @extend .text-center;
  @extend .mt-3;
}

.review-slider {
  .owl-nav {
    @extend .position-absolute;
    @extend .right-0;
    @extend .bottom-0;
    @include media-breakpoint-down(sm) {
      position: relative !important;
    }
  }
  .img--circle {
    width: 50px;
    border: solid 3px $theme-binnenmaas-1;
    border-radius: 100px;
  }
}
